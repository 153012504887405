import { NgModule} from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { SmartadminLayoutModule } from "./layout";

import {I18nModule} from "./i18n/i18n.module";
import {UtilsModule} from "./utils/utils.module";
import { BootstrapModule } from "@app/shared/bootstrap.module";
import {PipesModule} from "./pipes/pipes.module";
import { ReactiveFormsModule } from '@angular/forms';
// import { UserModule } from "./user/user.module";
// import {VoiceControlModule} from "./voice-control/voice-control.module";
 import {SmartadminWidgetsModule} from "./widgets/smartadmin-widgets.module";
import { FormlyModule } from "@ngx-formly/core";
import { NgFormlyConfig } from "./dynamic-form/formly";
import { FormlyBootstrapModule } from "@ngx-formly/bootstrap";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
// import { OwlcalenderComponent } from '@app/shared/ui/owlcalender/owlcalender.component';
// import {TimeLineModule} from '@app/shared/time-line/time-line/time-line.module'
// import { SharedMaterialModule } from "./material.module";

// import {ChatModule} from "./chat/chat.module";
// import {StatsModule} from "./stats/stats.module";
// import {InlineGraphsModule} from "./graphs/inline/inline-graphs.module";
// import {SmartadminFormsLiteModule} from "./forms/smartadmin-forms-lite.module";
// import {SmartProgressbarModule} from "./ui/smart-progressbar/smart-progressbar.module";
// import { CalendarComponentsModule } from "@app/shared/calendar/calendar-components.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,

    SmartadminLayoutModule,
    BootstrapModule,
    ReactiveFormsModule,
    SmartadminWidgetsModule,
    FormlyModule.forRoot(NgFormlyConfig),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    // TimeLineModule
    
  ],
  declarations: [
    // OwlcalenderComponent,
  ],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    // UserModule,
    SmartadminLayoutModule,
    BootstrapModule,

    I18nModule,

    UtilsModule,
    PipesModule,

    // SmartadminFormsLiteModule,

    // SmartProgressbarModule,

    // InlineGraphsModule,

     SmartadminWidgetsModule,

    // ChatModule,

    // StatsModule,

    // VoiceControlModule,

    // CalendarComponentsModule,
    FormlyModule,
    FormlyBootstrapModule,
    // OwlcalenderComponent,
    // OwlDateTimeModule,
    // OwlNativeDateTimeModule,
    // TimeLineModule
  ]
})
export class SharedModule {}
