import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AppService } from '@app/app.service';
// import { LoginInfoComponent } from '@app/shared/user/login-info/login-info.component';
import { EmitService } from '@app/ts/services/emit.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { CommonService } from '../services/common.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router,private appService : AppService,
    private commonservice:CommonService,private http: HttpClient,private emitService: EmitService) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean>|Promise<boolean>|boolean {
    let cookieExist = this.commonservice.getCookie(environment.checkCookie);
    if (cookieExist == "") {
      if( !localStorage.getEncryptedItem('ACCOUNTS_URL')){
        const host = window.location.host
        const parts = host.split(".");
        parts.shift();
        const domain = parts.join(".");
        let formData = new FormData();
        formData.append('domain', localStorage.getEncryptedItem('domain'));
        let object = this.commonservice.createObject(formData);
        let token = this.commonservice.createToken(object);
        let encryptedToken = this.commonservice.encrypt(token);
        let encrData = new FormData();
        encrData.append('encrToken', encryptedToken);
        let API_END_POINT_ACCOUNTS = 'https://fjy29.api.' + domain + '/api/v1/';
        // let API_END_POINT_ACCOUNTS = 'http://testaccounts.api.' + domain + ':8080/api/v1/';
        this.http.post(API_END_POINT_ACCOUNTS + 'auth/getWhiteLabelConfig', encrData).subscribe( dataFromApi => {
          if (dataFromApi["status"]) {
            let config = dataFromApi['data']['configdata'];
            localStorage.setEncryptedItem('API_END_POINT_ACCOUNTS', config['accounts_api_url']);
            localStorage.setEncryptedItem('API_END_POINT_ADMIN', config['admin_api_url']);
            localStorage.setEncryptedItem('API_END_POINT_GATE2B', config['gate2b_api_url']);
            localStorage.setEncryptedItem('ACCOUNTS_URL', config['accounts_url']);
            localStorage.setEncryptedItem('ADMIN_URL', config['admin_url']);
            localStorage.setEncryptedItem('GATE2B_URL', config['gate2b_url']);
            window.location.href = localStorage.getEncryptedItem('ACCOUNTS_URL')+'auth/logout/'+encodeURIComponent(this.commonservice.encrypt(window.location.href))
          }
        });
      }else{
        window.location.href = localStorage.getEncryptedItem('ACCOUNTS_URL')+'auth/logout/'+encodeURIComponent(this.commonservice.encrypt(window.location.href))
      }
    }
    else{
      let expiredCookie = this.commonservice.decrypt(decodeURIComponent(cookieExist))
      if (this.tokenExpired(expiredCookie)) {
        // token expired
        this.appService.createAccessToken().subscribe(data=>{ });
      } else {
        // token valid
      }
      const userDataRow = localStorage.getEncryptedItem('userData');
      const userData = userDataRow ? JSON.parse(userDataRow) : [];
      const isSubscriptionFound = userData.length > 0 ? userData[0].isSubscriptionFound : false;  
      if( !localStorage.getEncryptedItem('userData') || !isSubscriptionFound){
        let formData = new FormData();
        formData.append('code','ADMIN');
        formData.append("accId", localStorage.getEncryptedItem('accId'));
        formData.append("partyId",localStorage.getEncryptedItem('partyID'));
        let objectUser = this.commonservice.createObject(formData);
        let tokenUser = this.commonservice.createToken(objectUser);
        let encryptedTokenUser = this.commonservice.encrypt(tokenUser);
        let encrDataUser = new FormData();
        encrDataUser.append("encrToken", encryptedTokenUser);
        let tempPath = window.location.hash.replace('#/','') == '' ? 'companyDetails' : window.location.hash.replace('#/','');
        // console.log("environment.API_END_POINT_ACCOUNT[localStorage.getEncryptedItem('domain')] ",environment.API_END_POINT_ACCOUNT[localStorage.getEncryptedItem('domain')])
        this.http.post(localStorage.getEncryptedItem('API_END_POINT_ACCOUNTS')+'users/user-profile',encrDataUser).subscribe(dataFromApi => {
          let user = dataFromApi['data'];
          let permissions = String(user[0].permissions.Allow);
          this.commonservice.setCookie(environment.permissions,this.commonservice.encrypt(permissions),10000,localStorage.getEncryptedItem('domain'),'/');
          // localStorage.setEncryptedItem('permissions',permissions);
          localStorage.setEncryptedItem('userData', JSON.stringify(user));
          localStorage.setEncryptedItem('accId',user[0].accId);
          localStorage.setEncryptedItem('isCompanyConfirmed',user[0].isConfirmedParty.toString());
          localStorage.setEncryptedItem('isAddressEmpty',user[0].isAddressEmpty.toString());
          localStorage.setEncryptedItem('hasPermission',user[0].hasPermission.toString());
          localStorage.setEncryptedItem('hideTutorial',user[0].hidetutorial.toString());
          localStorage.setEncryptedItem('currency',user[0].currency?.toString());
          let userName = user[0].username;
          let companyList = user[0].companyDetails;
          let companyName = companyList.filter(item=>item.partyid == localStorage.getEncryptedItem('partyID'))[0]['companyname'];
          let userInfo = {};
          userInfo["userName"] = userName;
          userInfo["companyName"] = companyName;
          this.commonservice.setCookie(this.commonservice.encrypt(environment.userInfo),this.commonservice.encrypt(JSON.stringify(userInfo)),10000,localStorage.getEncryptedItem('domain'),'/');
          let permissionCookie = this.commonservice.getCookie(environment.permissions);
          if(!user[0].isSubscriptionFound) {
            window.location.href = localStorage.getEncryptedItem('BILLING_URL')+'user-plans';
            return;
          }
          if(this.commonservice.decrypt(permissionCookie).indexOf(route.data.right)==-1) {
            window.location.href = localStorage.getEncryptedItem('ACCOUNTS_URL')+'profile';
            return;
          }
          this.appService.notify('true');
          window.location.href = localStorage.getEncryptedItem('ADMIN_URL')+ tempPath ;
          setTimeout(() => {
            let language = user[0].language ? user[0].language : 'de';
            this.emitService.emitLoginLanguageChange(language);
          },100);
        });
      } 
      else{
        let permissionCookie = this.commonservice.getCookie(environment.permissions);
        if(this.commonservice.decrypt(permissionCookie).indexOf(route.data.right)==-1){
          window.location.href = localStorage.getEncryptedItem('ACCOUNTS_URL')+'profile';
          return

        }        
        return true;
      }
    }
    // return true;
  }

  private tokenExpired(token) {
    let cookieExpiredTime = JSON.parse(token)
    return new Date(cookieExpiredTime[environment.tokenExpireTime]).getTime() < new Date().getTime();
  }

}
