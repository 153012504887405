<div class="page-footer theme-bg-color w-100">
    <!-- <div class="row"> -->

    <!-- </div> -->
    <div class="row my-2 mx-1">
        <!-- <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 iconItems">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 helpIcon">
               <div><i class="fa fa-question-circle" aria-hidden="true" style="color: white;font-size: 20x;"></i><span style="font-size: 14px;color: #4b515a;padding-left: 10px;">Help</span></div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <sa-logout></sa-logout>
            </div>
        </div> -->
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 px-0">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8">
                <div class="row">
                    <ul class="footerItems footerTextColor">
                        <li>
                            <span class="li-footer__copy-text">© {{year}} </span>
                            <span class="footerTxt">{{companyName}}</span>
                        </li>
                        <li class="footerContent">
                            <a href="https://www.io-market.com/AGB.pdf" target="_blank"> <span class="li-footer__copy-text">{{'common_all_terms_and_conditions' | i18n}}</span></a>

                        </li>
                        <li class="footerContent">
                            <a href="https://www.io-market.com/ADV.pdf" target="_blank"> <span class="li-footer__copy-text">{{'common_all_data_processing_agreement' | i18n}}</span></a>

                        </li>
                        <li class="footerContent" >
                            <a href="https://www.io-market.com/privacy_policy.pdf" target="_blank"><span class="li-footer__copy-text">{{'common_all_privacy_policy' | i18n}}</span></a>

                        </li>
                        <li class="footerContent">
                            <a href="https://www.io-market.com/impressum.pdf" target="_blank"> <span class="li-footer__copy-text">{{'common_all_legal_notices' | i18n}}</span></a>

                        </li>
                    </ul>
                </div>
                <!-- <span class="txt-color-white">SmartAdmin 1.9.0 - Web Application Framework © 2017-2019</span> -->
            </div>

            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 text-right hidden-xs d-sm-none d-lg-block .d-xl-block">
                <div class="txt-color-white inline-block">
                    <!-- <i class="txt-color-blueLight hidden-mobile" style="font-size: 12px;">{{'common_activity' | i18n}} <i class="fa fa-clock-o"></i>
                    <strong> {{'common_activity_time' | i18n}} &nbsp;</strong> </i> -->

                    <div class="btn-group" dropdown dropup="true">
                        <!-- <button class="btn btn-xs dropdown-toggle bg-color-blue txt-color-white" dropdownToggle>
                            <i class="fa fa-link"></i> <span class="caret"></span>
                        </button> -->
                        <ul class="dropdown-menu" *dropdownMenu style="right: 0px; left: auto">
                            <li>
                                <div class="padding-5">
                                    <p class="txt-color-darken font-sm no-margin">Download Progress</p>

                                    <div class="progress progress-micro no-margin">
                                        <div class="progress-bar progress-bar-success" style="width: 50%;"></div>
                                    </div>
                                </div>
                            </li>
                            <li class="divider"></li>
                            <li>
                                <div class="padding-5">
                                    <p class="txt-color-darken font-sm no-margin">Server Load</p>

                                    <div class="progress progress-micro no-margin">
                                        <div class="progress-bar progress-bar-success" style="width: 20%;"></div>
                                    </div>
                                </div>
                            </li>
                            <li class="divider"></li>
                            <li>
                                <div class="padding-5">
                                    <p class="txt-color-darken font-sm no-margin">Memory Load <span class="text-danger">*critical*</span>
                                    </p>

                                    <div class="progress progress-micro no-margin">
                                        <div class="progress-bar progress-bar-danger" style="width: 70%;"></div>
                                    </div>
                                </div>
                            </li>
                            <li class="divider"></li>
                            <li>
                                <div class="padding-5">
                                    <button class="btn btn-block btn-default">refresh</button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>