export const environment = {
  production: true,
  firebase: {},
  debug: false,
  log: {
    auth: false,
    store: false,
  },
  smartadmin: {
    api: null,
    db: 'smartadmin-angular'
  },
  privateKey:{key:"MIIEpAIBAAKCAQEAx5DMt1FGNFvMerMldZeh7o8iPdSrknRV6kCJfTfA60lMsJtFRbgwmED6jdpXoYG+Zh+Lt9nsCm8Mkj2c8ZFa3Gx3ANVZAJqcz7ztR/HFxVw2VIDEbbpQuVfbbf9O1W4WzOgvuWidPNNXuY62WRNnE/H4MkYVE8Bh+dN/c3VQhGycbvmuYjV6cG/EQv21vrJkCn0dMAx/wzo9ZQyxcsU0jUEOJczbnQyr4UoYsWtNQIXpZTQStRR3pbvNeyP2Ab7zo1ridahE+UkETQclaif80fGZ/3DcMpCR1SD0mryUb8z3pAwrygzuORRjs0augbvhAZg2WjVvfWbVepgF3KBlbwIDAQABAoIBAHab6gAeLvKZiMt4RBBC4fxuV7SV/K8bs6B5YP3qMB/nPcxNTHumgENNg1zh42lbOnVp1FmeDLsay0bzlZ7BfFY7pPL62l4Qhpi1OfWjjZ7CMoxAx/5LWQT/YaxbrFgPiT+Z1BuHQMTQ+3eOiXQhowborlH68zX9Bn48JKjTldpNwg2YZvPeZidWGKhqkeQIgj/wsM0fGUl5aR+xqTRt5ZOydjITDXKFQh6Ms+lhW+w73AaKfVZ16VVWrBCXqIjnVM6HUuzSv2SeJmlHZetw9uvvHBDZf0kYzC3DLpTtyqLacoplKc3kxQiV/vJuiwubVgLqHDeEawqEH7jWjKJa1dECgYEA7ptjGuYk1l0H/fWQn0BlPRVZO/l37bbkzyYJZbRh2SAxYgeh+EluXviRXDKwcRSkCnyNSM6cDf33f5U9pw46mCCXZPvfoFaIRJ70SF/2CMtiXyiw+o1ykEBKr12LNiK829+NrAQph9CGaAhm5AmGDsjDN20192Kje2HJ4z5YX7cCgYEA1hzd9HVA9EYCylPzc4BeeZ191ltd053p2a8PVJ2k2cToOcnBz+81MOfPKiyNzfVEdgSS95yCjHRP0CkViVsp/UpVHhVhRYgDU5nskKMfHvu1VG6RPjnWBhzWIwJrm+E6yxl0/NMuLkqQk14tl2NhQqq3kgs/D0TuJks2o6QTOAkCgYBCJWF7+4EKzA+DH6hP/ZITNO/f6HCOvb0AkBLa1v6pKnuGKYeFRRYLzey+Gm8/afFv1sdz/5LTbixGeiD1RsSHl8FghVKhKFvuagJebfvOxFMo/ibRzpSL2MbbhpspDsg8S+UCYsUAmZYpEOs1HE7FmnJD00q6oeWgNqO5E3d8UQKBgQCNx72986rYVkfgvKiYEif9NimuuP+vcoD4sbBgIqeXwIjaQ1yAmXDKeqW20pvwBvqXf73mExBre1WijKIDO4fZftBQRG4OAdvaDzIUMMAZ3ymayECLSPcNQykVM7QYG5O4C5ppFFofMyb7PsDH33n3EF7QaXwPW2CroOCEidBkKQKBgQDnF4bqesdBbtlET7ZnxDtaCJw/kZg1SFdeJWDUCNDOKmMu5zCdoisDFjvt5qLIIPPDPiwxf0hQXGJl76afE9rz03IYC98DtjrKxNmJzH8832PcZgDE4Z//8Bjn4ht+WZN7zcMcpXftrMKVtMLkQCSeBOMaBz4qUj3FcgqOVSKMSA=="},
  encryptionKey : "RdAO2InTo6jl4ArLYyIInQ==",
  cookieDomain:".io-market.net",
  checkCookie:"expireCookie",
  tokenExpireTime:"cookieCreatedTime",
  languageCookie:'languageCookie',
  styleCookie:'styleCookie',
  WC_APPS:"https://wc.io-market.net/subscribedApps/main.js",
  WC_PROFILE:"https://wc.io-market.net/profile/main.js",
  USER_ACTIVE_CHECK:"isUserActive",
  userInfo:"userInfo",
  BEXIO_URL: "https://bexio.io-market.net/#/",
  MTC_URL: "https://mtc.io-market.net/#/",
  mtc_cookie: "mtc_cookie",
  permissions: 'permissions',
  color:"colorChangeTest",
  tax:"0.081",
  EBILL_ERROR_PRICE:"0.20",
  cookieConsent: 'cookieConsent'
};
